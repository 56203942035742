<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Hub Return List</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <router-link to="/hub-return-form" class="btn btn-info btn-sm float-right"><i class="fas fa-plus-circle"></i> Create</router-link>
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form>
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="title">Merchant</label>
                                                <v-select name="hub_id"
                                                    v-model="search.merchant_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= merchantList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="authUser.role_id != 7">
                                            <div class="form-group">
                                                <label for="title">{{ $t('globalTrans.hub') }}</label>
                                                <v-select name="hub_id"
                                                    v-model="search.hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="start_date">Start Date</label>
                                                <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="end_date">End Date</label>
                                                <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Total Returnable </th>
                                                    <th>Total Return On Way to Merchant</th>
                                                    <th>Total Returned</th>
                                                    <th>SLA Missed</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="summary">
                                                <tr class="text-center text-bold">
                                                    <td>{{ summary.total_returnable }}</td>
                                                    <td>{{ summary.total_return_onway_to_merchant }}</td>
                                                    <td>{{ summary.total_returned }}</td>
                                                    <td>{{ summary.total_sla_missed }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Merchant</th>
                                                    <th>Hub</th>
                                                    <th>Date</th>
                                                    <th>Total Order</th>
                                                    <th>Rider</th>
                                                    <th>OTP</th>
                                                    <th>Created By</th>
                                                    <th>Status</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Merchant</th>
                                                    <th>Hub</th>
                                                    <th>Date</th>
                                                    <th>Total Order</th>
                                                    <th>Rider</th>
                                                    <th>OTP</th>
                                                    <th>Created By</th>
                                                    <th>Status</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>BHK-MR-{{ item.id }}</td>
                                                    <td>{{ item.merchant ? item.merchant.business : '' }}</td>
                                                    <td>{{ item.hub ? item.hub.name : '' }}</td>
                                                    <td>{{ item.date | dateformat }}</td>
                                                    <td>{{ item.total_order }}</td>
                                                    <td>
                                                        <slot v-if="item.rider">
                                                            {{ item.rider.name }} <br/> {{ item.rider.mobile }}
                                                        </slot>                                                        
                                                    </td>
                                                    <td>{{ item.otp }}</td>
                                                    <td>{{ item.createdby ? item.createdby.name : '' }}</td>
                                                    <td>{{ getStatus(item.status) }}</td>
                                                    <td style="text-align:center;">
                                                        <button class="btn btn-primary btn-sm mr-1" @click="showOrder(item.id)" title="Show Order"> <i class="fa fa-eye"></i> </button>  
                                                        <button v-if="item.status == 1" class="btn btn-warning btn-sm mr-1"  @click="showOnWayModal(item.id)" title="Onway to Return"><i class="fa fa-undo" aria-hidden="true"></i></button>                                                         
                                                        <button class="btn btn-success btn-sm"  @click="downloadPdf(item.id)" title="PDF"><i class="fa fa-download" aria-hidden="true"></i></button>    
                                                        <button class="btn btn-info btn-sm ml-1" v-if="item.status == 2" @click="merchantReturnReceive(item.id)" title="Merchant Return Receive"> <i class="fa fa-check"></i> </button>                                                       
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>           
                                    </div>             
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        
        <!-- /.content -->
        <div v-if="onWayModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <b-overlay :show="loader">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Return On Way</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ValidationObserver ref="onWayForm" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(onWay)">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="edit_title">Return ID <span class="text-danger" title="Required">*</span></label>
                                            <input type="text" id="edit_title" :value="'BHK-MR-' + itemId" class="form-control" readonly>                            
                                        </div>   
                                    </div> 
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">  
                                        <ValidationProvider name="Rider" vid="rider_id" v-slot="{errors}" rules="required|min_value:1">              
                                            <div class="form-group">
                                                <label for="edit_title">Rider <span class="text-danger" title="Required">*</span></label>
                                                <select2 v-model="form.rider_id" placeholder="Select" :options="riderList"></select2>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>  
                                        </ValidationProvider>                  
                                    </div>                    
                                </div>   
                            </form>                 
                        </ValidationObserver>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-info" @click.prevent="onWay">Submit</button>
                        <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
                </b-overlay>
            </div>
        </div>
    </div>
</template>
<script>
import config from '@/config'
import Select2 from 'v-select2-component'
import moment from 'moment'
import ExportPdf from './Pdf'

export default {
    name:'Hub',
    components:{
        'select2': Select2
    },
    data(){
        return {
            loader: false,
            onWayModal: false,
            summary: null,
            search: {
                merchant_id: '',
                hub_id: '',
                start_date: moment().subtract(30,'d').format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD')
            },
            form: {
                rider_id: 0
            },
            itemId: 0,
            errors: [],
            createModal: false,
            editModal: false,
            deleteModal: false,
            key: '',
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created(){
        this.loadSummary()
        this.loadData()
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadSummary()
                this.loadData()
            }
        }
    },
    computed : {
        stateReload () {
            return this.$store.state.stateReload
        },
        listData () {
            return this.$store.state.list
        },
        merchantList () {
            return this.$store.state.commonObj.merchantList
        },
        hubList () {
            return this.$store.state.commonObj.hubList
        },
        authUser () {
            return this.$store.state.authUser
        },
        riderList () {
            if (this.authUser.role_id == 7) {
                return this.$store.state.commonObj.riderList.filter(el => el.hub_id === this.authUser.hub_id)
            }
            
            return this.$store.state.commonObj.riderList
        }
    },
    methods:{
        searchData () {
            this.loadSummary()
            this.loadData()
        },
        async loadSummary () {
            this.loader = true
            const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search 
            const response = await config.getData('/hub/return/summary', params)
            this.loader = false
            this.summary = response.data          
        },
        async loadData(){     
            this.loader = true 
            const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id, page: this.pagination.currentPage, per_page: this.pagination.perPage }) : this.search 
            const response = await config.getData('/hub/return/list', params)
            this.loader = false
            if (response.success){
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        getStatus (status) {
            if (status == 1) {
                return 'Pending'
            } else if (status == 2) {
                return 'On way to Merchant'
            } else if (status == 3) {
                return 'Merchant Received'
            }
        },
        showOnWayModal (itemId) {
            this.itemId = itemId
            this.onWayModal = true
        },
        cancelModal () {
            this.onWayModal = false
        },
        async onWay(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            this.form = Object.assign(this.form, { return_id: this.itemId })
            const response = await config.postData('/hub/return/on-way', this.form)
            this.loader = false
            if (response.success) {
                this.$store.dispatch('commonObjLoad', true)
                this.onWayModal = false;
                this.form = ''   
                this.$toast.success('Return to onway successfully')   
            } else {
                 this.$refs.onWayForm.setErrors(response.errors)
                this.$toast.error('Sorry, something went wrong')  
            }
            this.$store.dispatch('stateReload', false)
        },
        getBase64Logo (logo) {
            var canvas = document.createElement('canvas')
            canvas.width = logo.width
            canvas.height = logo.height
            var ctx = canvas.getContext('2d')
            ctx.drawImage(logo, 0, 0)
            var dataURL = canvas.toDataURL('image/png')
            return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
        },
        async downloadPdf(itemId){
            this.loader = true
            const response = await config.getData(`/hub/return/details/${itemId}`)
            this.loader = false
            if (response.success) {
                this.pdfDownload(response.data, response.merchant)   
            } else {                
                this.$toast.error('Sorry, something went wrong')  
            }
        },
        pdfDownload(datas, merchant) {
            // this.loader = true
            const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
            ExportPdf.returnPdfDetails(base64Logo, datas, merchant)
            // this.loader = false
        },
        showOrder (id) {
            this.$router.push({ name: 'hub.return.show.order', query: { id: id } })
        },
        async merchantReturnReceive (id) {
            this.loader = true
            // this.$store.dispatch('stateReload', true)
            const response = await config.getData(`/return/merchant-receive/${id}`)                
            this.loader = false
            if (response.success) {  
                this.loadData();
                this.$toast.success({
                    title: 'Success',
                    message: 'Merchant return received.',
                    color: '#218838'
                })
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })
            }
            // this.$store.dispatch('stateReload', false)  
        }
    }
}
</script>