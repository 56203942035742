<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Rider Advanced Salary</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form v-on:keyup.enter="searchData">
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12" v-if="authUser.role_id <= 3">
                                            <div class="form-group">
                                                <label for="hub_id">Hub</label>
                                                <v-select name="hub_id"
                                                    v-model="search.hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="rider_id">Rider</label>
                                                <v-select name="rider_id"
                                                    v-model="search.rider_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= riderList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="rider_id">Rider Mobile</label>
                                                <input type="text" id="contact" v-model="search.rider_mobile" placeholder="Enter Mobile No" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="start_date">Start Date</label>
                                                <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="end_date">End Date</label>
                                                <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12">
                                            <div class="form-group" style="margin-top:28px;">
                                                <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                                <a v-if="authUser.role_id <= 3" class="btn btn-warning btn-sm" target="_blank" :href="$baseUrl + 'excel-download/rider-advanced?start_date='+search.start_date+'&end_date='+search.end_date+'&rider_id='+ search.rider_id +'&hub_id='+ search.hub_id">
                                                    <i class="fa fa-download"></i> Excel
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Total Rider</th>
                                                    <th>Total Advanced</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="summary">
                                                <tr class="text-center text-bold">
                                                    <td>{{ summary.total_rider }}</td>
                                                    <td>{{ summary.total_advanced }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                            <div class="card-body mt-20">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm text-center">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Rider Name</th>
                                                    <th>Rider Mobile</th>
                                                    <th>Date</th>
                                                    <th>Reason</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Rider Name</th>
                                                    <th>Rider Mobile</th>
                                                    <th>Date</th>
                                                    <th>Reason</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>{{ item.id }}</td>
                                                    <td>{{ item.rider ? item.rider.name : '' }}</td>
                                                    <td>{{ item.rider ? item.rider.mobile : '' }}</td>
                                                    <td>{{ item.date | dateformat }}</td>
                                                    <td>{{ item.reason }}</td>
                                                    <td>{{ item.amount }}</td>
                                                    <td>{{ getStatus(item.status) }}</td>
                                                    <td style="text-align:center;">                                    
                                                        <button v-if="authUser.role_id <= 2" class="btn btn-warning btn-sm mr-1" @click="editModal(item)" title="Edit"><i class="fas fa-pencil-alt"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Add New Rider Advance Salary </h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(createRider)">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Rider" vid="rider_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="rider_id">Rider <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="hub_id"
                                                        v-model="form.rider_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= riderList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>       
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Amount" vid="amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="amount">Amount <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="amount" v-model="form.amount" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ValidationProvider name="Reason" vid="reason" v-slot="{errors}" rules="max:200">
                                                <div class="form-group">
                                                    <label for="reason">Reason  <span class="text-danger" title="Required">*</span></label>
                                                    <textarea id="reason" v-model="form.reason" class="form-control" placeholder="Maximum 200 word.."></textarea>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="createRider">Save</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="riderEditModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document"> 
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Edit Rider Advance Salary</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateRider)">                                    
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Rider" vid="rider_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="rider_id">Rider <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="rider_id"
                                                        v-model="form_edit.rider_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= riderList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>       
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Amount" vid="amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="amount">Amount <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="amount" v-model="form_edit.amount" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ValidationProvider name="Reason" vid="reason" v-slot="{errors}" rules="max:200">
                                                <div class="form-group">
                                                    <label for="reason">Reason  <span class="text-danger" title="Required">*</span></label>
                                                    <textarea id="reason" v-model="form_edit.reason" class="form-control" placeholder="Maximum 200 word.."></textarea>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="updateRider">Update</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="riderDeleteModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyRider">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    export default {
        name:'Rider',
        data(){
            return {  
                loader: false, 
                search: {
                    rider_id: '',
                    hub_id: '',
                    start_date: moment().subtract(30,'d').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD')
                },
                summary: null,
                form:{
                    rider_id : '',
                    hub_id : '',
                    date: moment().format('YYYY-MM-DD'),
                    amount: '',
                    reason: ''
                },
                form_edit:'',
                errors:[],
                riderList: [],
                createModal:false,
                riderEditModal:false,
                riderDeleteModal:false,
                rider_id:'',
                key:'',
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created () {
            this.loadSummary()
            this.loadData();
            if (this.authUser.role_id == 7) {
                this.getHubWiseRiderList(this.authUser.hub_id)
            }
        },
        watch: {
            stateReload : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadSummary()
                    this.loadData()
                }
            },
            'search.hub_id' : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.getHubWiseRiderList(newVal)
                }
            }
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            commonObj () {
                return this.$store.state.commonObj
            },
            authUser () {
                return this.$store.state.authUser
            },
            // riderList () {
            //     if (this.authUser.role_id == 7) {
            //         return this.$store.state.commonObj.riderList.filter(el => el.hub_id === this.authUser.hub_id)
            //     }
                
            //     // return this.$store.state.commonObj.riderList
            // },
            hubList () {
                if (this.authUser.role_id == 7) {
                    return this.$store.state.commonObj.hubList.filter(el => el.hub_id === this.authUser.hub_id)
                }
                
                return this.$store.state.commonObj.hubList
            }
        },
        methods:{
            searchData () {
                this.loadSummary()         
                this.loadData()         
            },
            async loadSummary () {
                this.loader = true
                const params = this.search
                const response = await config.getData('/rider-advanced-salary/summary', params)
                this.loader = false
                this.summary = response.data          
            },
            async loadData(){     
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
                const response = await config.getData('/rider-advanced-salary', params)
                this.loader = false
                if (response.success){
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                } 
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            getHubWiseRiderList(hubId){
                const newRiderList = this.$store.state.commonObj.riderList.filter(el => el.hub_id === hubId)
                this.riderList = newRiderList
            },
            showCreateModal(){
                this.createModal = true;
                document.body.classList.add("modal-open");
            },
            async createRider(){ 
                this.loader = true
                this.$store.dispatch('stateReload', true)

                const response = await config.postData('/rider-advanced-salary/store', this.form)
                this.loader = false
                if (response.success) {
                    this.form.rider_id = 0
                    this.form.amount = this.form.reason = ''
                    this.$store.dispatch('commonObjLoad', true)
                    this.createModal = false        
                    this.$toast.success({
                        title: 'Success',
                        message: 'Rider created successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            cancelModal(){
                this.createModal = false;
                this.riderEditModal = false;
                this.riderDeleteModal = false;
                document.body.classList.remove("modal-open");
            },
            editModal(rider){
                this.riderEditModal = true;
                this.form_edit = rider;
                document.body.classList.add("modal-open");
            },
            async updateRider(){
                this.loader = true
                this.$store.dispatch('stateReload', true)

                const response = await config.postData('/rider-advanced-salary/update', this.form_edit)

                this.loader = false
                if (response.success) {
                    this.$store.dispatch('commonObjLoad', true)
                    this.riderEditModal = false         
                    this.$toast.success({
                        title: 'Success',
                        message: 'Data updated successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            deleteModal(key, rider_id) { 
                this.rider_id = rider_id;
                this.key = key;
                this.riderDeleteModal = true;
            },
            async destroyRider() {
                this.loader = true
                this.$store.dispatch('stateReload', true)
                await config.getData('/rider/destroy/'+ this.rider_id)
                this.loader = false           
                this.riderDeleteModal = false
                this.$toast.success({
                    title: 'Success',
                    message: 'Data deleted successfully',
                    color: '#218838'
                }) 
                this.$store.dispatch('stateReload', false)
            },
            getStatus(status) {
                if (status == 1) {
                    return 'Pending'
                } else if (status == 2) {
                    return 'Added in Deposit'
                } else if (status == 3) {
                    return 'Adjusted'
                } else if (status == 4) {
                    return 'Deleted'
                }

            }
        }
    }
</script>