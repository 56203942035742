<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">RTM to Hub List</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="id">RTM to Hub ID</label>
                                            <input type="text" id="id" v-model="search.id" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="title">{{ $t('globalTrans.hub') }}</label>
                                            <v-select name="hub_id"
                                                v-model="search.hub_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= hubList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="date">Start Date</label>
                                            <input type="date" id="date" v-model="search.start_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="date">End Date</label>
                                            <input type="date" id="date" v-model="search.end_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                            <button class="btn btn-warning btn-sm ml-1" @click.prevent="reload" style="margin-top:28px;"><i class="fa fa-sync-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Hub</th>
                                                    <th>Total Order</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Created By</th>
                                                    <th>Updated By</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Hub</th>
                                                    <th>Total Order</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Created By</th>
                                                    <th>Updated By</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>{{ item.id }}</td>
                                                    <td>{{ item.hub ? item.hub.name : '' }}</td>
                                                    <td>{{ item.total_order }}</td>
                                                    <td>{{ item.date | dateformat }}</td>
                                                    <td><span :class="'return-status-'+ item.status">{{ getStatus(item.status) }}</span></td>
                                                    <td>{{ item.createdby ? item.createdby.name : '' }}</td>
                                                    <td>{{ item.updatedby ? item.updatedby.name : '' }}</td>
                                                    <td style="text-align:center;">
                                                        <button class="btn btn-primary btn-sm mr-1" @click="showOrder(item.id)" title="Show Order"> <i class="fa fa-eye"></i> </button>  
                                                        <button class="btn btn-success btn-sm"  @click="downloadPdf(item.id)" title="PDF"><i class="fa fa-download" aria-hidden="true"></i></button> 
                                                        <button class="btn btn-info btn-sm ml-1" v-if="item.status == 1" @click="returnReceive(item.id)" title="Return Receive"> <i class="fa fa-check"></i> </button>                                                        
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>  
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    import 'jspdf-autotable';
    import ExportPdf from './HubToRtm'

    export default {
        name:'RtmToHub',
        data(){
            return {   
                loader: false,             
                loading: false,   
                summary: null,   
                search: {
                    id: '',
                    hub_id: '',
                    start_date : moment().subtract(10,'d').format('YYYY-MM-DD'),
                    end_date   : moment().format('YYYY-MM-DD')
                },
                key:'',
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created(){
            this.loadData()
        },
        watch: {
            stateReload : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData()
                }
            }
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            hubList: function () {
                return this.$store.state.commonObj.hubList
            }
        },
        methods:{
            reload () {
                this.search = Object.assign({}, {
                    id: '',
                    hub_id: '',
                    start_date : moment().subtract(10,'d').format('YYYY-MM-DD'),
                    end_date   : moment().format('YYYY-MM-DD')
                })
            },
            onImageChange(e){
                this.pay_now.image = e.target.files[0];
            },
            searchData () {
                this.loadData()
            },
            async loadData(){    
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })       
                const response = await config.getData('/return/rtm-to-hub-list', params)
                this.loader = false
                if (response.success){
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                } 
            },
            async returnReceive(returnId){    
                this.loader = true 
                const params = { return_id: returnId }       
                const response = await config.postData('/return/rtm-to-hub-return-receive', params)
                this.loader = false
                if (response.success){
                    this.loadData();
                    this.$toast.success({
                        title: 'Success',
                        message: 'Return parcel received from RTM',
                        color: '#218838'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    }) 
                } 
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            showOrder (id) {
                this.$router.push({ name: 'rtm_to_hub_details', query: { id: id } })
            },
            getStatus (status) {
                if (status == 1) {
                    return 'On way to Hub'
                } else if (status == 2) {
                    return 'On way to Merchant'
                } else if (status == 3) {
                    return 'Merchant Received'
                } else if (status == 4) {
                    return 'Hub Return Received'
                }
            },
            async downloadPdf(itemId){
                this.loader = true
                const response = await config.getData(`/return/hub-to-rtm-details/${itemId}`)
                this.loader = false
                if (response.success) {
                    this.pdfDownload(response.data)   
                } else {                
                    this.$toast.error('Sorry, something went wrong')  
                }
            },
            pdfDownload(data) {
                const hub = this.hubList.find(el => el.id == this.authUser.hub_id)
                const hubName = hub ? hub.text : ''
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportPdf.hubToRtmPdfDetails(base64Logo, data, hubName)
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            }
        }
    }
</script>
