<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h4 class="m-0 text-dark">Make Return Order of <b>{{ merchant_business }}</b></h4>
                    </div>
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <b-overlay :show="loader">
                            <div class="card">                                
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="merchant_id">Rider</label>
                                                <v-select name="rider_id"
                                                    v-model="form.rider_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= riderList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="title">{{ $t('globalTrans.status') }}</label>
                                                <select v-model="form.status" class="form-control">
                                                    <option :value="23">On way to Source HUB</option>
                                                    <option :value="25">Return On way to Merchant</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group" style="margin-top:28px">
                                                <button class="btn btn-danger float-left btn-sm ml-2" @click="makeReturn"> Make Return</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table id="exportPdfTable" class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Deadline</th>
                                                    <th>Customer</th>
                                                    <th>Price (BDT)</th>
                                                    <th>Collected (BDT)</th>
                                                    <th>Service Charge</th>
                                                    <th>Return Charge</th>
                                                    <th>Area Charge</th>
                                                    <th>Weight Charge</th>
                                                    <th>COD</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(order, index) in listData" :key="index">
                                                    <td>
                                                        <label>
                                                            <input v-model="form.order_ids" @click="singleSelect(order.id)" :value="order.id" type="checkbox" class="order-checkbox" />
                                                            <p class="d-inline">{{ order.id }}</p>
                                                        </label>
                                                    </td>
                                                    <td @click="orderLog(order.logs)">{{ order.date | dateformat }}</td>
                                                    <td>{{ order.name }}<br/>{{ order.mobile }}</td>
                                                    <td>{{ order.price }}</td>
                                                    <td>{{ order.collected }}</td>
                                                    <td>{{ order.service_charge }}</td>
                                                    <td>{{ order.return_charge }}</td>
                                                    <td>{{ order.area_charge }}</td>
                                                    <td>{{ order.weight_charge }}</td>
                                                    <td>{{ order.cod }}</td>
                                                    <td>{{ order.amount }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </b-overlay>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
        <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Log</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-sm">
                            <tr>
                                <th style="width:30%">Action By</th>
                                <th>Action</th>
                                <th>Date & Time</th>
                            </tr>
                            <tr v-for="(log, index) in order_logs" :key="index">
                                <td>{{ log.operation_by }}</td>
                                <td>{{ log.operation }}</td>
                                <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                            </tr>
                        </table>                       
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    import 'jspdf-autotable'
    export default {
        name:'Form',
        data(){
            return {   
                orderLogModal:false,
                allSelected:false,
                order_logs:[],
                loader: true,             
                listData:[],
                key:'',
                merchant_business: null,
                searchParams: null,
                form: {
                    order_ids: [],
                    rider_id: '',
                    status: 25
                }
            }
        },
        created () {
            this.searchParams = {
                merchant_id : this.$route.params.merchant_id,
                start_date : this.$route.params.start_date,
                end_date : this.$route.params.end_date
            }
            this.getOrders();
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            riderList: function () {
                return this.$store.state.commonObj.riderList
            }
        },
        methods:{
            singleSelect(orderId) {
                const tmpOrder = this.form.order_ids.indexOf(orderId)
                if (tmpOrder > -1) {
                    this.form.order_ids.splice(tmpOrder, 1)                   
                } else {
                    this.form.order_ids.push(orderId)
                }      
            },
            async getOrders(){     
                this.loader = true 
                const response = await config.getData('/return/cancel-order', this.searchParams)
                this.loader = false
                if (response.success){
                    response.data.filter(item => { 
                        this.form.order_ids.push(item.id)
                    })
                    this.listData = response.data
                    this.merchant_business = response.merchant_business
                } else {
                    this.listData = []
                    this.merchant_business = ''
                }
            },
            async makeReturn () {
                if (this.form.rider_id == '') {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Please select rider',
                        color: '#dc3545'
                    }) 
                } else {

                    this.loader = true
                    const formData = { rider_id: this.form.rider_id, status: this.form.status, merchant_id: this.$route.params.merchant_id, hub_id: this.authUser.hub_id, order_ids: this.form.order_ids }
                    const response = await config.postData('/return/return-store', formData)    
                    this.loader = false 
                    if (response.success) {
                        this.$toast.success({
                            title: 'Success',
                            message: 'Retrurn Maked Successfully',
                            color: '#218838'
                        })
                        this.$router.push({ name: 'return' })
                    } else {
                        this.$toast.error({
                            title: 'Error',
                            message: 'Sorry, something went wrong',
                            color: '#dc3545'
                        }) 
                    }
                }
            },  
            orderLog (logs) {
                this.order_logs = logs 
                this.orderLogModal = true
            },
            cancelModal () {
                this.orderLogModal = false
            }
        }
    }
</script>

<style scoped>
    .d-inline {
        display: inline-block;
        padding-left: 3px;
    }
</style>