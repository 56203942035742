import JsBarcode from 'jsbarcode' 
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_pdf_kal_fonts_bn'

const expostPosDetails = async (base64Logo, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })

    pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    pdfMake.fonts = {
      Kalpurush: {
        normal: 'Kalpurush.ttf',
        bold: 'Kalpurush.ttf',
        italics: 'Kalpurush.ttf',
        bolditalics: 'Kalpurush.ttf'
      }
    }

    var pdfContent = []
    const date = new Date()
    const pageBreak = vm.length > 1 ? 'after' : ''

    vm.forEach(item => {
        var singlePos = [
            { qr: item.id.toString(), fit: '50', alignment: 'center', style:'qr' },
            { text: 'BAHOK COURIER LIMITED', style: 'poshead', alignment: 'center' },
            { text: item.merchant ? (item.merchant.business + ' | '+ item.merchant.mobile) : '', style: 'merchant', alignment: 'center' },

            { text: item.name + ', ' + item.mobile + (item.mobile_2 ? (' / ' + item.mobile_2) : ''), alignment: 'left', style: 'td' },
            { text: item.address, alignment: 'justify', style: 'td' },
            { text: item.instruction ? 'Instruction: '+ item.instruction : '', alignment: 'justify', style: 'td' },
            { text: 'COD : ' + (item.price != 0 ? item.price : 'Paid') , alignment: 'center', style: 'cod' },
            { text: (item.destination ? item.destination.name : '') + ' --> ' + item.area.name, alignment: 'center', style: 'cod' },
            { image: 'data:image/' + generateBarcode(item.id), alignment: 'center' },
            { text: item.tracking_id + (item.exchange == 1 ? ' | Exchange' : ''), alignment: 'center', style: 'cod' },
            { text: 'Ref ID: '+ item.ref_id +', Date: '+ item.date, alignment: 'center', style: 'td' },
            { text: Store.state.authUser.name +' | www.bahokcourier.com | ' + date.toDateString() + ' at '+ date.toLocaleTimeString(), alignment: 'center', style: 'website', pageBreak: pageBreak }
        ]
        pdfContent.push(singlePos)
    })

    var docDefinition = {
      content: pdfContent,
      pageSize: {
        width: 160,
        height: 'auto'
      },
      defaultStyle: {
        font: 'Kalpurush'
      },
      pageMargins: [ 10, 10, 10, 10],
      styles: {
        th: {
          fontSize: 10,
          margin: [0, 3, 3, 3]
        },
        td: {
          fontSize: 8,
          margin: [0, 1, 0, 0]
        },
        merchant: {
          fontSize: 6,
          margin: [0, 2, 2, 4]
        },
        tdaddress: {
          fontSize: 8,
          margin: [0, 2, 2, 5]
        },
        org: {
          margin: [5, 0, 0, 5]
        },
        qr: {
          margin: [5, 0, 0, 5]
        },
        cod: {
          fontSize:  9,
          margin: [0, 0, 0, 0]
        },
        tracking_id: {
          fontSize: 8,
          margin: [5, 0, 0, 5]
        },
        website: {
          fontSize: 5,
          italics: true,
          margin: [0, 0, 0, 8]
        },
        poshead: {
          fontSize: 8,
          margin: [5, 0, 0, 0]
        },
        logo: {
          margin: [0, 0, 0, 0]
        }
      }
    }
    var win = window.open('', '_blank')
    pdfMake.createPdf(docDefinition).open({}, win)
    // pdfMake.createPdf(docDefinition).print()
  } catch (error) {
    if (error) {
      console.log('err => ', error)
    }
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}

function generateBarcode (orderNo='12345678') {
  let canvas = document.createElement('canvas');
  JsBarcode(canvas, orderNo, { 
      format: 'CODE128',
      displayValue: false,
      height: 30,
      width: 1,
      padding: 0,
      margin: 0
  });
  return canvas.toDataURL('image/png');
}

export default {
    expostPosDetails
}
