<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Users</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="title">{{ $t('globalTrans.hub') }}</label>
                                            <v-select name="hub_id"
                                                v-model="search.hub_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= hubList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="name">Name</label>
                                            <input type="text" id="name" v-model="search.name" placeholder="Enter Name" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="email">Email</label>
                                            <input type="text" id="email" v-model="search.email" placeholder="Enter Email Address" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="mobile">Mobile No</label>
                                            <input type="text" id="mobile" v-model="search.mobile" placeholder="Enter Mobile No" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="role_id">Role <span class="text-danger" title="Required">*</span></label>
                                            <v-select name="role_id"
                                                v-model="search.role_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= roleList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>User</th>
                                                    <th>Email</th>
                                                    <th>Address</th>
                                                    <th>Hub</th>
                                                    <th>Image</th>
                                                    <th>Role</th>
                                                    <th>Created By</th>
                                                    <th>Updated By</th>
                                                    <th>Status</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>User</th>
                                                    <th>Email</th>
                                                    <th>Address</th>
                                                    <th>Hub</th>
                                                    <th>Image</th>
                                                    <th>Role</th>
                                                    <th>Created By</th>
                                                    <th>Updated By</th>
                                                    <th>Status</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="user in listData" :key="user.id">
                                                    <td>{{ user.name }} <br/> {{ user.mobile }}</td>
                                                    <td>{{ user.email }}</td>
                                                    <td>{{ user.address }}</td>
                                                    <td>{{ user.hub ? user.hub.name : '' }}</td>
                                                    <td><img :src="$image_path+user.image" alt="User Image" style="width:80px;height:50px;"/></td>                    
                                                    <td>{{ user.role ? user.role.name : '' }}</td>    
                                                    <td>{{ user.createdby ? user.createdby.name : '' }}</td>    
                                                    <td>{{ user.updatedby ? user.updatedby.name : '' }}</td>    
                                                    <td> {{ user.status == 0 ? 'Inactive' : 'Active' }}</td>            
                                                    <td style="text-align:center;">    
                                                        <button v-if="user.status == 0" class="btn btn-success btn-sm mr-1" @click="statusUpdate(1, user)" title="Active"><i class="fas fa-check"></i></button>
                                                        <button v-if="user.status == 1" class="btn btn-danger btn-sm mr-1" @click="statusUpdate(0, user)" title="Inactive"><i class="fas fa-user-slash"></i></button>                                                                   
                                                        <button class="btn btn-warning btn-sm mr-1" @click="editModal(user)" title="Edit"><i class="fas fa-pencil-alt"></i></button>
                                                        <!-- <button class="btn btn-danger btn-sm" @click="deleteModal(key, user.id)" title="Delete"><i class="fas fa-trash"></i></button> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" id="addHubModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Add New User</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(createUser)">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Name">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Email" vid="email" v-slot="{errors}" rules="required"> 
                                                <div class="form-group">
                                                    <label for="email">Email <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="email" v-model="form.email" class="form-control" placeholder="Email">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>           
                                            </ValidationProvider>             
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min_value:11">
                                                <div class="form-group">
                                                    <label for="mobile">Mobile <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="mobile" v-model="form.mobile" class="form-control" placeholder="Mobile" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div> 
                                            </ValidationProvider>                       
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="business" v-model="form.address" class="form-control" placeholder="Enter Address">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Password" vid="password" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="password">Password <span class="text-danger" title="Required">*</span></label>
                                                    <input type="password" id="password" v-model="form.password" class="form-control" placeholder="Enter Password">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Image" vid="image" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="image">Image</label>
                                                    <input type="file" id="image" @change="onImageChange" class="form-control">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Role" vid="role_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="role_id">Role <span class="text-danger" title="Required">*</span></label> 
                                                    <v-select name="role_id"
                                                        v-model="form.role_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= roleList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="hub_id"
                                                        v-model="form.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="form.role_id < 5">
                                            <ValidationProvider name="Payment Permission" vid="payment_permission" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="payment_permission">Payment Permission <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="payment_permission"
                                                        v-model="form.payment_permission"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= paymentPermissionList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="createUser">Save</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="userEditModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Edit User</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                             <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateUser)">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Name">
                                                    <input type="hidden" name="id" v-model="form_edit.id" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Email" vid="email" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="email">Email <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="email" v-model="form_edit.email" class="form-control" placeholder="Email">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>            
                                            </ValidationProvider>            
                                        </div>                        
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min_value:11">
                                                <div class="form-group">
                                                    <label for="mobile">Mobile <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="mobile" v-model="form_edit.mobile" class="form-control" placeholder="Mobile" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                    
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="address" v-model="form_edit.address" class="form-control" placeholder="Address">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>  
                                            </ValidationProvider>                      
                                        </div> 
                                    </div>
                                    <div class="row">                
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Password" vid="password" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="password">Password</label>
                                                    <input type="password" id="password" v-model="form_edit.password" class="form-control">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Image" vid="image" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="image">Update Image</label>
                                                    <input type="file" id="image" @change="onImageChange" class="form-control">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Role" vid="role_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="roles">Role <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="role_id"
                                                        v-model="form_edit.role_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= roleList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>   
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="form_edit"
                                                        v-model="form_edit.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="form_edit.role_id < 5">
                                            <ValidationProvider name="Payment Permission" vid="payment_permission" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="payment_permission">Payment Permission <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="payment_permission"
                                                        v-model="form_edit.payment_permission"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= paymentPermissionList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </form>
                             </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="updateUser">Update</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="userDeleteModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyUser">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
    import config from '@/config'
    export default {
        data(){
            return {   
                loader: false,             
                roles:[],
                users:{
                    data: []
                },
                search: {
                    name: '',
                    email: '',
                    mobile: '',
                    role_id: '',
                    hub_id: ''
                },
                form:{
                    name : '',
                    email : '',
                    mobile : '',
                    address : '',
                    image : '',
                    value : null,
                    roles : [],
                    hub_id: '',
                    payment_permission: 0,
                    password : ''
                },
                paymentPermissionList: [
                    { id: 0, text: 'None' },
                    { id: 1, text: 'Maker' },
                    { id: 2, text: 'Checker' },
                ],
                tmpImage: [],
                form_edit:'',
                errors:[],
                createModal:false,
                userEditModal:false,
                userDeleteModal:false,
                user_id:'',
                key:'',
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created(){
            this.loadData();
        },
        watch: {
            stateReload : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData()
                }
            },
            'search.name' : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData()
                }
            }
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            roleList () {
                return this.$store.state.commonObj.roleList
            },
            hubList () {
                return this.$store.state.commonObj.hubList
            },
            listData () {
                return this.$store.state.list
            },
            locale () {
                return this.$store.state.language
            }
        },
        methods:{
            onImageChange(e){
                this.tmpImage = e.target.files[0];
            },
            searchData () {
                this.loadData()
            },
            async loadData(){     
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
                const response = await config.getData('/user', params)
                this.loader = false
                if (response.status == 200){
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                } 
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            showCreateModal(){
                this.createModal = true;
                document.body.classList.add("modal-open");
            },
            async createUser(){
                this.loader = true
                this.$store.dispatch('stateReload', true)

                var formData = new FormData();
                Object.keys(this.form).map(key => {
                    if (key === 'image') {
                        formData.append(key, this.tmpImage)
                    } else {
                        formData.append(key, this.form[key])
                    }
                })

                const response = await config.postData('/user/store', formData)

                this.loader = false
                if (response.status == 201) {
                    this.createModal = false         
                    this.$toast.success({
                        title: 'Success',
                        message: 'User created successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            cancelModal(){
                this.createModal = false;
                this.userEditModal = false;
                this.userDeleteModal = false;
                document.body.classList.remove("modal-open");
            },
            editModal(user){
                this.userEditModal = true;
                this.form_edit = user
                document.body.classList.add("modal-open");
            },
            async updateUser(){
                this.loader = true
                this.$store.dispatch('stateReload', true)

                var formData = new FormData()
                Object.keys(this.form_edit).map(key => {
                    if (key === 'image') {
                        formData.append(key, this.tmpImage)
                    } else {
                        formData.append(key, this.form_edit[key])
                    }
                })

                const response = await config.postData('/user/update', formData)
                this.loader = false
                if (response.status == 201) {
                    this.userEditModal = false         
                    this.$toast.success({
                        title: 'Success',
                        message: 'User updated successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            deleteModal(key, user_id){ 
                this.user_id = user_id;
                this.key = key;
                this.userDeleteModal = true;
            },
            async destroyUser(){
                this.loader = true
                this.$store.dispatch('stateReload', true)
                await config.deleteData('/user/destroy/'+ this.user_id)
                this.loader = false           
                this.deleteModal = false
                this.$toast.success({
                    title: 'Success',
                    message: 'User deleted successfully',
                    color: '#218838'
                }) 
                this.$store.dispatch('stateReload', false)
            },
            async statusUpdate(status, user){ 
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.getData('/user/status-update/' + user.id + '/' + status)
                this.loader = false
                if (response.status == 201) {      
                    this.$toast.success({
                        title: 'Success',
                        message: 'Status updated successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>